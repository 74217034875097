import Swiper from 'swiper';

export function swiperHandler() {
	//Need to iterate over sliders so it doesnt bug out with multiple instances
	$('.test-container').each(function(index, element){
		var $this = $(this);
		$this.addClass('instance-' + index);
		$this.find('.js-prev-slide').addClass('js-prev-slide-' + index);
		$this.find('.js-next-slide').addClass('js-prev-slide-' + index);
		var galleryElement = document.querySelector('.gallery-thumbs');
		var slidesPerView =  galleryElement.getAttribute('data-totalSlider');
		var numberOfSlides = slidesPerView > 3 ? 4 : slidesPerView;

		// console.log(galleryElement.getAttribute('data-totalSlider'));

		var galleryThumbs = new Swiper('.gallery-thumbs', {
			spaceBetween: 10,
			slidesPerView: numberOfSlides,
			// loop: true,
			// freeMode: true,
			// loopedSlides: 5, //looped slides should be the same
			// watchSlidesVisibility: true,
			watchSlidesProgress: true,
		  });
		  var galleryTop = new Swiper('.gallery-top', {
			spaceBetween: 10,
			// loop:true,
			// loopedSlides: 5, //looped slides should be the same
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			},
			thumbs: {
			  swiper: galleryThumbs,
			},
		  });


		// var galleryTop = new Swiper('.instance-' + index, {
		// 	// Optional parameters
		// 	direction: 'horizontal',
		// 	spaceBetween: 10,
		// 	loop: true,
		// 	effect: 'fade', 
		// 	// speed: 100,   
		// 	// Navigation arrows
		// 	navigation: {
		// 		nextEl: '.swiper-button-next',
		// 		prevEl: '.swiper-button-prev',
		// 	  },
		// 	pagination: '.swiper-pagination',
		// 	// prevButton: '.swiper-button-prev',
		// 	// nextButton: '.swiper-button-next',
		// 	loopedSlides: 5, //looped slides should be the same
	    //     paginationClickable: true,
	    //     spaceBetween: 30,
		// 	loop: true,
		// 	thumbs: {
		// 		swiper: galleryThumbs
		// 	}
		// });
		// var galleryThumbs = new Swiper('.gallery-thumbs', {
		// 	direction: 'horizontal',
		// 	spaceBetween: 10,
		// 	slidesPerView: 4,
		// 	loop: true,
		// 	freeMode: true,
		// 	loopedSlides: 5, //looped slides should be the same
		// 	watchSlidesVisibility: true,
		// 	watchSlidesProgress: true,
		// 	touchRatio: 0.2,
		// 	slideToClickedSlide: true,
		// 	paginationClickable: true,
		//   });
		// galleryTop.controller.control = galleryThumbs;
		// galleryThumbs.controller.control = galleryTop;

		// galleryTop.on('slideChange', function () {
		// 	console.log('slide changed');
		// 	console.log(galleryTop.activeIndex);
		// });

		// $(".gallery-thumbs").on('click', 'div', function(){
		// 	galleryTop.slideTo($(this).index(), false);
		// 	console.log('click');
		// 	console.log(this);
		// 	console.log($(this).index());
		// });

	});
}