// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
import $ from "jquery";
import jQuery from "jquery";
// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

import Swiper from "swiper";
import { swiperHandler } from "./swiperHandler.js";

$(document).ready(function() {
	swiperHandler();

	// Mobile menu
	$("#hamburger").click(function(event) {
		event.stopPropagation();
		$(this).toggleClass("is-open");
		$("#menu-main-menu").toggleClass("is-open");
		$("#top .logo-holder").toggleClass("white");
	});
	$(document).click(function() {
		if ($("#hamburger").hasClass("is-open")) {
			$("#hamburger").removeClass("is-open");
			$("#menu-main-menu").removeClass("is-open");
			$("#top .logo-holder").removeClass("white");
		}
	});
	var url_string = window.location.href;
	var url = new URL(url_string);
	if (url.searchParams.get("gclid")) {
		localStorage.setItem("referer", "google");
	}
	if (url.searchParams.get("utm_source")) {
		localStorage.setItem("referer", url.searchParams.get("utm_source"));
	}
	$(".js-submit-form").click(function() {
		$("input").removeClass("error");
		var error = 0;
		var data = {
			referer: localStorage.getItem("referer")
				? localStorage.getItem("referer")
				: "Direktlänk eller sökmotor",
			name: $("#name").val(),
			email: $("#email").val(),
			questions: $("#questions").val(),
			action: "submit_signup_form"
		};
		var success = function() {
			$('#name').val('')
			$('#email').val('')
			$('#questions').val('')
			$("#gdpr"). prop("checked", false)
			$("#error").hide();
			$("#success").show();
			dataLayer.push({ event: "formSubmit" });
		};
		var errorfunc = function() {
			$("#error").show();
			$("#success").hide();
		};
		if ($("#name").val() == "") {
			$("#name").addClass("error");
			error++;
		}
		if ($("#email").val() == "") {
			$("#email").addClass("error");
			error++;
		}
		if (!$("#gdpr").is(":checked")) {
			$(".checkbox").addClass("error");
			error++;
		}
		if (error <= 0) {
			$.ajax({
				type: "POST",
				url: window.ajaxurl,
				data: data,
				success: success,
				error: errorfunc
			});
		} else {
			console.log(error, "fel fel fel!!");
		}
	});
});

let flag = false;

$('.cat-else').each(function(index, item) {
	if($(item).hasClass('cat-active')) {
		flag = true;
	}
});

if(!flag){
	$('.cat-all').addClass('cat-active');
}